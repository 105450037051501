import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { useStorage } from '@vueuse/core'
import { RouterNames } from './router.consts'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: RouterNames.Home,
    component: () => import('@/views/Home/Home.vue'),
    redirect: () => {
      return { name: RouterNames.Login, params: { id: '' } }
    },
    children: [
      {
        path: '/:id/details',
        name: RouterNames.Details,
        component: () => import('@/views/Details/DetailsPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/:id/gallery',
        name: RouterNames.Gallery,
        component: () => import('@/views/Gallery/GalleryPage.vue'),
        props: (route) => {
          return {
            presentation: route.query?.presentation,
          }
        },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/:id',
    name: RouterNames.Login,
    component: () => import('@/views/Login/Login.vue'),
  },
  {
    path: '/404',
    name: RouterNames.NotFound,
    component: () => import('@/views/NotFound/NotFound.vue'),
  },
  {
    path: '*',
    redirect: () => {
      return { name: RouterNames.Login }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  const galleryId = useStorage('galleryId', '')
  const accessToken = useStorage('accessToken', '')

  const _galleryId = to.params.id
  if (_galleryId && _galleryId !== galleryId.value) {
    galleryId.value = _galleryId
    accessToken.value = null
  }

  if (accessToken.value && to.name === RouterNames.Login && to.params.id) {
    next({ name: RouterNames.Details, params: { id: to.params.id } })
  }

  if (to?.meta?.requiresAuth && !accessToken.value) {
    next({
      name: RouterNames.Login,
      params: { id: to.params.id || galleryId.value },
    })
    // window.location.href = `/${galleryId.value}`
  } else {
    next()
  }
})

export default router
